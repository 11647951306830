import React, { useState } from "react"
import styled from "styled-components";

export function SearchWrapper(props) {
	const { loading, submit, onInput, children,
		...rest } = props

	const [searchValue, setSearchValue] = useState('')
	return (
		<SearchWrapperStyled>
			<div className="flex flex-row">

				<div className={`search-input-box normal-input--wrap flex`}>
					<input
						className={`amount-input normal-input`}
						placeholder={"Search Keyword"}
						type={'text'}
						onChange={(event) => {
							const value = event.target.value
							setSearchValue(value)
						}}
						onInput={onInput ? onInput : null}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								submit(searchValue);
							}
						}}
						{...rest}
					/>
				</div>
				<div className={`blog-search-button cursor-pointer`} onClick={() => submit(searchValue)}>
					Search
				</div>
			</div>
		</SearchWrapperStyled>
	)
}

const SearchWrapperStyled = styled.div`
	position: relative;
	margin-top: 14px;
	
	.search-input-box {
		margin-right: 13px;
		border-radius: 5px;
		border: 1px solid var(--bgOffset, #F4F4F4);
		background: #FFF;
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
		height: 35px;
		flex-shrink: 0;
	}
	.amount-input {
		min-height: 35px;
		font-family: Muli;
	}
	.formLabel {
		font-size: 0.9125rem;
	}
	.blog-search-button {
		border-radius: 5px;
		background: #DFE3FF;
		width: 99px;
		height: 35px;
		flex-shrink: 0;
		color: #40196D;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		font-family: Muli;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;