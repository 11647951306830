import React, { useEffect, Fragment } from "react"
import CTA from "../general/cta"
import { scrollToElement } from "../../../utility/utils"
import { useState } from "react"
import { getBlogCategories, getSelectedGroupBlogs } from "./blog-details/api-utility"
import BlogCategoriesSection from "./components/blog-categories-section"
import { BlogListCards } from "./components/blog-list-cards"
import { BlogListCardsSkeleton } from "./components/blog-list-cards-skeleton"
import { BlogListEmptyState } from "./components/blog-list-empty-state"
const Blog = () => {
  const [selectedFilter, setSelectedFilter] = useState()
  const [state, setState] = useState({
    categoriesFailed: false,
    blogsFailed: false,
    isCategoriesLoaded: false,
    isBlogsLoading: true,

  })
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState([])
  const [allBlogs, setAllBlogs] = useState([])
  const [filteredBlogs, setFilteredBlogs] = useState([])
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })

    const fetchData = async () => {
      const result = await getBlogCategories();

      if (result.resultStatus === 200) {
        setFilters(result.filters)
        setState({
          ...state,
          isCategoriesLoaded: true,
          categoriesFailed: false,
          isBlogsLoading: true
        })
        const selectedGroup = result.filters?.[0]
        setSelectedFilter(selectedGroup)
        if (selectedGroup) {
          changeFilterGroup(selectedGroup, null)
        }
      } else {
        setState({
          ...state,
          isCategoriesLoaded: true,
          categoriesFailed: true,
          isBlogsLoading: false
        })
      }
    }
    fetchData()
  }, [])
  useEffect(() => {
    if (searchValue) {
      changeFilterGroup(selectedFilter, searchValue)
    }

  }, [searchValue])


  async function changeFilterGroup(value, searchInputValue) {
    setState({ ...state, isBlogsLoading: true })

    if (value) {
      console.log(value, '>>val')
      const blogs = await getSelectedGroupBlogs(value, searchInputValue)
      setAllBlogs(blogs);
      setFilteredBlogs(blogs)
      setState({ ...state, isBlogsLoading: false, categoriesFailed: false })
    }
  }
  return (
    <Fragment>
      <div className="kuda-inner--heading">
        <div className="kuda-section--inner">

          <div className="kuda-section--100 text-center">
            <h1 className="text-xl text-xlbold color-primary title-bottom--spacing">
              Blog
            </h1>
            <div className={`kuda-section--100 flex justify-center`}>
              <div className="kuda-main--wrap flex flex-column align-center mt-0">
                <span className="card--heading text-semi-bold color-black text-center">
                  Changed our train of thought, get fresh updates and dive into eye-opening money guides.
                </span>
              </div>
            </div>

          </div>

          {state.categoriesFailed ? <BlogListEmptyState title={"Internal server error"} subTitle={"Sorry, an unexpected error occured. Please try again."} /> : <>
            <BlogCategoriesSection filters={filters}
              selectedFilter={selectedFilter}
              onFilterGroupChanged={(value) => {
                setSearchValue('')
                setSelectedFilter(value)
                changeFilterGroup(value, null)
              }}
              onSearchValueChanged={(value) => {
                if (value.length === 0 && !state.isBlogsLoading) {
                  changeFilterGroup(selectedFilter, value)
                }
                setSearchValue(value)

              }} />


            <div className="kuda-ignore--container">
              {state.isBlogsLoading ? <BlogListCardsSkeleton blogNumber={2} /> : filteredBlogs.length > 0 ? <BlogListCards blogs={filteredBlogs} /> : <BlogListEmptyState />}
            </div>

          </>}

        </div>
      </div>
      <CTA />
    </Fragment>
  )
}

export default Blog
