import React from "react"
import styled from "styled-components";
import { SearchWrapper } from "./search-wrapper-input";

export default function BlogCategoriesSection(props) {
    const { filters, selectedFilter, onFilterGroupChanged, onSearchValueChanged } = props

    return (
        <div className="kuda-ignore--container">
            <div className="kuda-section--100 kuda-spacing--inner kuda-disclaimer--section">
                <FilterGroupStyled>
                    <div className="filter-divider">
                        <div className="flex flex-column flex-lg-row justify-between container">
                            <div className=" flex flex-row justify-between">
                                <div className="flex flex-row filters">{filters?.map((filter, index) => {
                                    const isSelected = filter?.categoryKey === selectedFilter?.categoryKey;
                                    return (<div key={index} className={`filter  ${isSelected ? "color-secondary" : "cursor-pointer color-primary"}`} onClick={() => onFilterGroupChanged(!isSelected ? filter : null)}>
                                        {filter?.categoryName}
                                    </div>)
                                })}</div>
                            </div>
                            <div className="flex flex-row justify-between">
                                <SearchWrapper submit={onSearchValueChanged} />
                            </div>
                        </div>

                    </div>

                </FilterGroupStyled>
                <h1 className="container kuda-section--heading text-left text-xlbold color-primary bottom--spacing ">
                    {selectedFilter?.displayName}
                </h1>
            </div>

        </div>
    )
}


export const FilterGroupStyled = styled.div`
    padding-bottom: 10px;

    @media (min-width:768px) {
        padding-bottom: 20px;
    }
    .filter {
        font-weight: 700;
        font-size: 14px;
        padding-bottom: 23px;
        padding-top: 23px;

    }
    div.filter:not(:first-child) {
        margin-left:43px;
    }
    .filter-divider {
        width: 100vw;
        border: 1px solid #F9F9F9;
        background: #FFF;
    }
`