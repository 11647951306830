
export async function getRelatedBlogs(selectedFilter) {
  try {
    const response = await fetch(`${process.env.BLOGCMS_URL}/api/blogs?sort[0]=publishedDate:desc&filters[categories][categoryKey][$eq]=${selectedFilter.categoryKey}&populate=*`);
    const result = await response.json();
    const blogs = result.data.map(a => {
      const imageAttributes = a.attributes?.blogHeroImage?.data?.attributes
      delete a?.attributes?.blogHeroImage
      return { ...a.attributes, id: a.id, image: imageAttributes ? imageAttributes : null }
    })
    return blogs
  } catch (error) {
    return []
  }
}


export async function getBlogCategories() {
  try {
    const res = await fetch(`${process.env.BLOGCMS_URL}/api/categories?populate=*`);
    if (!res.ok) {
      return { error: "Throw error", isLoaded: true, filters: [], resultStatus: 500 }
    }
    const result = await res.json();

    const filters = result.data.map(info => {
      return {
        id: info.id,
        ...info.attributes
      }
    })
    return { isLoaded: true, resultStatus: 200, filters: filters }
  } catch (e) {
    return { isLoaded: true, filters: [], resultStatus: 500, error: e }
  }

}
export async function getSelectedGroupBlogs(selectedFilter, searchValue) {
  let url = `sort[0]=publishedDate:desc&filters[categories][categoryKey][$eq]=${selectedFilter.categoryKey}&populate=*`
  if (searchValue) {
    url = `filters[blogTitle][$containsi]=${searchValue}&${url}`

  }
  try {
    const response = await fetch(`${process.env.BLOGCMS_URL}/api/blogs?${url}`);
    const result = await response.json();
    const blogs = result.data.map(a => {
      const imageAttributes = a.attributes?.blogHeroImage?.data?.attributes
      delete a?.attributes?.blogHeroImage
      return { ...a.attributes, id: a.id, image: imageAttributes ? imageAttributes : null }
    })
    return blogs
  } catch (error) {
    return []
  }
}
export async function getBlogDetails(title) {
      const errorBody = {
        status: 500,
        isLoaded: true, seoTitle: "Page not found", seoDescription: ""
            }
  try {
    const res = await fetch(`${process.env.BLOGCMS_URL}/api/blogs?filters[blogTitle][$eq]=${title}&populate[blogHeroImage][populate]=*&populate[blogContent][populate]=*&populate[linkedBlogs][populate]=*&populate[linkedBlogs][blogHeroImage]fields[0]=url&populate=*`);
    if (!res.ok) {
      return errorBody
    }
    const result = await res.json();
    if (result.data?.length > 0) {
      const resultData = result.data?.[0]?.attributes
      const imageUrl = resultData?.blogHeroImage?.data?.attributes
      delete resultData?.blogHeroImage
      return {
        status: 200,
        isLoaded: true, id: result.data?.[0]?.id, ...resultData, image: imageUrl
      }
    } else {
      return errorBody
    }
  } catch (error) {
    return errorBody
  }
}