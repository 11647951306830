import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Blog from "../../components/body/pages/blog"

const BlogPage = () => (
    <Layout>
        <SEO
            title="Kuda’s Blog | Follow Kuda’s thought’s, get updates, news and dive into helpful information | Kuda"
            description="Recent news, Product Updates, Marketing Insights and Company Information from Kuda"
            noIndex={true}
        />
        <Blog/>
    </Layout>
)

export default BlogPage

